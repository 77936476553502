import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import './App.css';

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [userId, setUserId] = useState('unique-user-id'); // Replace with dynamic user ID generation

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      useTLS: true,
    });

    const chatChannel = pusher.subscribe(`chat-channel-${userId}`);

    chatChannel.bind('new_message', function(data) {
      setMessages(prevMessages => [...prevMessages, { sender: data.sender, message: data.message }]);
    });

    return () => {
      chatChannel.unbind_all();
      chatChannel.unsubscribe();
    };
  }, [userId]);

  const sendMessage = async () => {
    if (input.trim()) {
      const response = await fetch('https://live-chat-backend-mu.vercel.app/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          sender: 'admin', // Replace with the appropriate sender identifier
          message: input,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setMessages(prevMessages => [...prevMessages, { sender: 'admin', message: input }]);
        setInput('');
      } else {
        console.error('Error sending message:', data.message);
      }
    }
  };

  return (
    <div className="App">
      <h1>Admin Dashboard</h1>
      <div>
        <h2>Messages</h2>
        <ul>
          {messages.map((msg, index) => (
            <li key={index}>
              <span>{msg.sender}: {msg.message}</span>
            </li>
          ))}
        </ul>
      </div>
      <input 
        type="text" 
        value={input} 
        onChange={e => setInput(e.target.value)} 
        placeholder="Type a message..."
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
}

export default App;
